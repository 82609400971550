import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { LoadingStatusComponent } from '../../loading-status/loading-status.component';
import { Observable } from 'rxjs';
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';

export interface DialogData {
  dialogTitle: string;
  dialogContent: string;
  dialogContentSuccess: string;
  dialogContentError: string;
  dataLoaded: Observable<boolean>;
  hasError: Observable<boolean>; 
}

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, LoadingStatusComponent, NgIf],
})
// En el componente del diálogo
export class LoadingPopupComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  currentLoadingStatus: boolean = false;
  hasError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LoadingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this.data.dataLoaded.pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => {
      this.currentLoadingStatus = status;
    });

    // Subscription to error state
    if (this.data.hasError) {
      this.data.hasError.pipe(
        takeUntil(this.destroy$)
      ).subscribe(error => {
        this.hasError = error;
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


