import { Component, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from "@angular/material/paginator";

import { ConfirmDeletePopupComponent } from '../shared/popup/confirm-delete-popup/confirm-delete-popup.component';
import { ConfirmPopupComponent } from "../shared/popup/confirm-popup/confirm-popup.component";
import { DigitalTwin, DigitalTwinType } from '../shared/models/digital-twin.dto';
import { MessageService } from '../shared/services/message.service';
import { HelpersService } from "../shared/services/helpers.service";
import { AlertHandlerService } from '../shared/services/alert-handler.service';

@Component({
  selector: 'app-digital-twin-test',
  templateUrl: './digital-twin-test.component.html',
  styleUrls: ['./digital-twin-test.component.css']
})
export class DigitalTwinTestComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  DigitalTwinType = DigitalTwinType;
  dataLoaded = false;

  showFirstLastButtons = true;

  toggleState: string = 'All';
  filterType: string = 'All';
  showToggles: boolean = false

  cards: any[] = [];
  allCards: any[] = [];
  pagedItems: any[] = [];
  searchTerm: string = '';
  breadcrumb: any = [{ title: 'Manually created digital twins', href: '' }];

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly http: HttpClient,
    public dialog: MatDialog,
    public messageService: MessageService,
    private readonly alertHandlerService: AlertHandlerService) {}

  ngOnInit() {
    this.getInformation();
  }

  getInformation() {
    this.http.get<DigitalTwin[]>("/api/v1/digitaltwin/test").pipe(
      map((res: DigitalTwin[]) => res.map((x: DigitalTwin) => ({
        id: x.id,
        cxIdentification: x.cxIdentification,
        cxGlobalAssetId: x.cxGlobalAssetId,
        bpn: x.bpn,
        materialNumberBASF: x.materialNumberBASF,
        customerNumberList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerMaterialNumber'),
        customerNameList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerName'),
        customerBPNList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerBpn'),
        batchNumber: x.batchNumber,
        isRegistered: x.isRegistered,
        digitalTwinType: x.digitalTwinType
      }))),
    ).subscribe({
      next: (response: any[]) => {
        this.allCards = response;
        HelpersService.applyFilters(this.cards,
          this.allCards,
          this.filterType,
          this.toggleState,
          this.searchTerm,
          this.paginator,
          (filteredCards: any[]) => {
            this.cards = filteredCards;
            this.updatePagedItems();
          }
        );
        this.dataLoaded = true;
      },
      error: (error: any) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    });

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.alertHandlerService.showMessage(message.text);
      }
    });
  }

  getAAS(cxIdentification: string) {
    this.dataLoaded = false;

    this.http.get<any>(`/api/v1/dtr/${cxIdentification}`).subscribe(res => {
      this.openAASPopup(res);
      this.dataLoaded = true;
    })
  }

  getComponentHistory(id: string) {
    HelpersService.getComponentHistory(id, true, this.http, this.dialog);
  }

  registerDigitalTwin(id: string) {
    this.dataLoaded = false;
    this.http.post<DigitalTwin>(`/api/v1/registry/${id}`, {}).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Digital Twin registered successfully!");
        
        this.getInformation()

      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    )
  }

  unregisterDigitalTwin(id: string, showDeleteConfirmation: boolean = false) {
    this.dataLoaded = false;
    this.http.delete(`/api/v1/registry/${id}`).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Digital Twin unregistered successfully!");

        if (showDeleteConfirmation) {
          this.deleteConfirmationPopup(id);
        }

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    )
  }

  deleteDigitalTwin(id: string) {
    this.dataLoaded = false;

    this.http.delete(`/api/v1/digitaltwin/${id}`).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Digital Twin deleted successfully!");
        
        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    )
  }

  openConfirmationPopup(digitalTwin: DigitalTwin) {
    if (!digitalTwin.isRegistered) {
      this.deleteConfirmationPopup(digitalTwin.id);
    } else {
      this.unregisterConfirmationPopup(digitalTwin.id, true);
    }
  }

  deleteConfirmationPopup(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeletePopupComponent, {
      data: { elementToDelete: 'Digital Twin', id: id },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.deleteDigitalTwin(id);
    });
  }

  unregisterConfirmationPopup(id: string, showDeleteConfirmation: boolean = false) {
    let dialogContent: string;
    let dialogTitle: string;

    if (showDeleteConfirmation) {
      dialogTitle = 'Unregister/Delete Digital Twin';
      dialogContent = `
      <div>The Digital Twin you are trying to delete is registered. It has to be unregistered first and then deleted.</div>
      <br/><div>Do you want to unregister it?</div>`;
    } else {
      dialogTitle = 'Unregister Digital Twin';
      dialogContent = '<div>Are you sure you want to unregister the selected Digital Twin?</div>';
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: dialogTitle,
        dialogContent: dialogContent,
        buttonText: 'Unregister'
      },
      position: { top: '200px' },
      maxWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.unregisterDigitalTwin(id, showDeleteConfirmation);
    });
  }

  createAspectModel(id: string, aspectModelFormRoute: string) {
    this.router.navigate([`/${aspectModelFormRoute}`, { 'digitalTwinId': id }]);
  }

  openAASPopup(aas: any) {
    let json: string = JSON.stringify(aas, null, 2);

    this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: 'View DT as JSON',
        dialogContent: `<pre id="pre">${json}</pre>`,
        buttonText: 'Ok'
      },
      position: { top: '100px' },
      maxWidth: '90vw',
      width: '60vw'
    });
  }

  onStateChange(event: string) {
    this.toggleState = event;
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  onTypeChange(event: string) {
    this.filterType = event;
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  applySearch() {
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  updatePagedItems() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    this.pagedItems = this.cards.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  pageChanged() {
    this.updatePagedItems();
  }

  protected readonly HelpersService = HelpersService;
}
