import { Component, EventEmitter, Input, Output } from '@angular/core';

interface ToggleState {
  displayValue: string;
  value: string;
}

@Component({
  selector: 'select-component',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent {
  @Input() states: ToggleState[] = [{displayValue: '', value: ''}];
  @Input() state: string = this.states[0].value;
  @Input() label: string = '';
  @Output() stateChange = new EventEmitter<string>();
  @Input() formFieldClass: string = '';
}
