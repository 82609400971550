<div class="navigation-row d-flex flex-row" *ngIf="isUserLogged">
  <ng-container *ngFor="let item of menuItems; let i = index">
    <ng-container *ngIf="canShowMenuItem(item)">
      <!-- Direct option -->
      <button mat-button *ngIf="item.dropdownOption === false" [routerLink]="item.children[0].link"
        [ngClass]="{'active-menu-item': currentUrl === item.children[0].link}" class="navigation-item">
        {{item.name}}
      </button>

      <ng-container *ngIf="item.dropdownOption === true">
        <button mat-button [matMenuTriggerFor]="menu" [ngClass]="{'active-menu-item': isActive(item)}"
          class="navigation-item">
          {{item.name}}
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let child of item.children" [routerLink]="child.link"
            [ngClass]="{'active-submenu-item': currentUrl === child.link}">
            {{child.name}}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</div>