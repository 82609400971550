<app-card-layout [title]="'Catalog'">

  <div class="margin-top-20">
    <mat-form-field appearance="outline" class="width-48 margin-right-20">
      <mat-label>Company</mat-label>
      <input type="text"
             matInput
             [formControl]="searchCompanyControl"
             [matAutocomplete]="auto"
             placeholder="Search...">
      <mat-autocomplete #auto="matAutocomplete"
                       [displayWith]="displayFn"
                       (optionSelected)="onCompanySelected($event)">
        <mat-option *ngFor="let company of connectorListFiltered | async" 
                    [value]="company">
          {{company.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="bpn-width">
      <mat-label>Company BPN</mat-label>
      <input matInput placeholder="Enter connector protocol URL" [(ngModel)]="connectorBpn" readonly>
    </mat-form-field>

    <div class="align-right margin-bottom-20 margin-left-auto">
      <button mat-raised-button color="primary" (click)="searchCatalog()" [disabled]="isSearchCatalogButtonDisabled">
        <mat-icon>search</mat-icon>
        Search Catalog
      </button>
    </div>

    <div class="vertical-align-center">
      <mat-form-field appearance="outline" class="width-48">
        <mat-label>Catalog URL</mat-label>
        <mat-select [(ngModel)]="searchUrl" (selectionChange)="onConnectorSelected($event)">
          <mat-option *ngFor="let connector of connectorList2" [value]="connector">
            {{ connector }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" class="margin-right-20 asset-limit-width">
      <mat-label>Assets Number</mat-label>
      <input matInput placeholder="Enter connector protocol URL" [(ngModel)]="assetLimit" type="number">
    </mat-form-field>

    <select-component [states]=searchByOptions [(state)]="searchBy" label="Search by"
      formFieldClass="margin-right-20 asdasdasd asset-limit-width" />

    <ng-container *ngIf="searchBy === searchByTypeValue">
      <select-component [states]=assetTypeOptions [(state)]="assetType" label="Asset Type" />
    </ng-container>

    <ng-container *ngIf="searchBy === searchByIdValue">
      <mat-form-field appearance="outline" class="width-48">
        <mat-label>Asset Id</mat-label>
        <input matInput placeholder="Enter asset id" [(ngModel)]="assetId">
      </mat-form-field>
    </ng-container>
  </div>

  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="assetId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.assetId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assetType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Type</th>
        <td mat-cell *matCellDef="let element">
          {{ extractSubmodelName(element.type) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
          <button mat-icon-button (click)="openAssetPopup(element.assetId)" matTooltip="View Asset">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Transfer Data" (click)="negotiateContract(element)">
            <mat-icon color="primary">compare_arrows</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-table>
</app-card-layout>