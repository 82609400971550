import { Customer } from "../models/digital-twin.dto";
import { Audit } from "../models/audit";
import { ConfirmPopupComponent } from "../popup/confirm-popup/confirm-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class HelpersService {

  constructor(public dialog: MatDialog) {
  }

  static getCustomerPropertyListString(customers: Customer[], property: keyof Customer) {
    const unknownValue: string = 'Unknown';

    if (customers === undefined) {
      return '';
    }

    const propertyList = customers.map(customer => {
      const value = customer[property];
      if (typeof value === 'string') {
        return value.trim() === '' ? unknownValue : value;
      }
      if (value === null || value === undefined) {
        return unknownValue;
      }
      return (value as any).toString();
    });
    return propertyList.join(', ');
  }

  static getTrimmedMaterialNumber(materialNumber: string): string {
    const numbers = materialNumber.split(', ');
    const trimmedNumbers = numbers.map(number => number.replace(/^0+/, ''));
    if (trimmedNumbers.length > 1) {
      return trimmedNumbers.slice(0, 1).join(', ') + ', ...';
    } else {
      return trimmedNumbers.join(', ');
    }
  }

  static getComponentHistory(id: string, digitalTwinHistory: boolean, http: HttpClient, dialog: MatDialog) {
    let keyQueryParam: string;
    let route: string;
    
    if (digitalTwinHistory) {
      keyQueryParam = 'digitalTwinId';
      route = '/api/v1/audit/dt';
    } else {
      keyQueryParam = 'aspectModelId';
      route = '/api/v1/audit';
    }

    let queryParams = new HttpParams().append(keyQueryParam, id);


    http.get<Audit>(route, { params: queryParams }).subscribe(res => {
      HelpersService.openHistoryPopup(res, dialog)
    })
  }

  static openHistoryPopup(audit: Audit, dialog: MatDialog) {
    let dialogContent: string;

    if (audit) {
      let modificationDate: string = (audit.modificationDate) ? audit.modificationDate : '-';

      dialogContent = `
        <ul>
          <li>Creation Date: ` + audit.creationDate + `</li>
          <li>Modification Date: ` + modificationDate + `</li>
          <li>Username: ` + audit.userName + `</li>
        </ul>`;
    } else {
      dialogContent = '<span>There is no registry for this component</span>';
    }

    dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: 'Component History',
        dialogContent: dialogContent,
        buttonText: 'Ok'
      },
      position: { top: '200px' },
      maxWidth: '50vw',
    });
  }

  static applyFilters(
    cards: any[],
    allCards: any[],
    filterType: string,
    toggleState: string,
    searchTerm: string,
    paginator: MatPaginator,
    updatePagedItemsCallback: (filteredCards: any[]) => void
  ) {
    const filteredCards = allCards.filter(card => {
      let typeFilter = true;
      let registeredFilter = true;
      let searchFilter = true;

      if (filterType !== 'All') {
        typeFilter = card.digitalTwinType === filterType;
      }

      if (toggleState !== 'All') {
        registeredFilter = (card.isRegistered === (toggleState === 'true'));
      }

      if (searchTerm) {
        searchFilter = card.materialNumberBASF.toLowerCase().includes(searchTerm.toLowerCase());
      }

      return typeFilter && registeredFilter && searchFilter;
    });

    paginator.length = filteredCards.length;
    updatePagedItemsCallback(filteredCards);
  }

}
