import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { map, startWith } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";

import { environment } from "../../environments/environment";
import { ConfirmPopupComponent } from "../shared/popup/confirm-popup/confirm-popup.component";
import { Asset } from "../shared/models/edc/asset";
import { CatalogDTO } from "../shared/models/edc/catalog.dto";
import { LoadingPopupComponent } from "../shared/popup/loading-popup/loading-popup.component";
import { TransferData } from "../shared/models/edc/transfer-data";
import { CompanyDTO } from "../shared/models/edc/company.dto";
import { AlertHandlerService } from "../shared/services/alert-handler.service";

@Component({
  selector: 'app-edc-catalog',
  templateUrl: './edc-catalog.component.html',
  styleUrls: ['./edc-catalog.component.css']
})
export class EdcCatalogComponent {
  dataSource = new MatTableDataSource<Asset>();
  dataLoaded = true;
  displayedColumns: string[] = ['assetId', 'assetType', 'options'];
  httpOptions = { headers: { 'Content-Type': 'application/json' } };
  showFirstLastButtons = true;
  searchUrl: string = '';
  assetLimit: number = 60;
  connectorBpn: string = '';

  searchByTypeValue: string = 'searchByType';
  searchByIdValue: string = 'searchById';

  searchByOptions = [
    { displayValue: 'Asset Type', value: this.searchByTypeValue },
    { displayValue: 'Asset Id', value: this.searchByIdValue }
  ];
  searchBy: string = this.searchByTypeValue;

  assetType: string | null = '';
  assetTypeOptions = [
    { displayValue: 'All', value: '' },
    { displayValue: 'Digital Twin Registry', value: 'DigitalTwinRegistry' },
    { displayValue: 'Submodel', value: 'Submodel' },
    { displayValue: 'Quality Notification', value: 'ReceiveQualityInformation' },
    { displayValue: 'PCF', value: 'PcfExchange' },
    { displayValue: 'Demand & Capacity', value: 'DcmWeekBasedMaterialDemand' }
  ];

  searchCompanyControl = new FormControl<string | CompanyDTO>(''); // For the search Company input
  selectCompanyControl = new FormControl(''); // For the select Company input
  connectorList: CompanyDTO[] = [];
  connectorListFiltered: Observable<CompanyDTO[]>;

  connectorList2: string[] = [];

  assetId: string | null = '';

  @ViewChild(MatTable) table!: MatTable<any>;

  transferData: TransferData = null;

  constructor(
    private readonly http: HttpClient,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly alertHandlerService: AlertHandlerService
  ) {
  }

  ngOnInit(): void {
    this.getConnectorList();

    const storedSearchUrl = sessionStorage.getItem('searchUrl');
    if (storedSearchUrl) {
      this.searchUrl = storedSearchUrl;
    }
  }


  /**
   * Fetches the list of connectors from the server and updates the component's state.
   * 
   * This method sends an HTTP GET request to the `/api/v1/edc/connectors` endpoint to retrieve
   * the list of connectors. Upon a successful response, it updates the `connectorList` and 
   * `connectorListFiltered` properties with the retrieved data and sets the default connector.
   * 
   * Additionally, it sets up a filter for the `connectorListFiltered` property based on the 
   * value changes of the `searchCompanyControl` form control.
   * 
   * In case of an error during the HTTP request, it logs the error to the console and updates 
   * the `loadingStatus` observable to `true`.
   * 
   * @returns {void}
   */
  getConnectorList() {
    this.http.get<any>("/api/v1/edc/connectors")
      .subscribe({
        next: (response) => {
          this.connectorList = response;
          this.connectorListFiltered = response;

          this.setDefaultConnector();

          this.connectorListFiltered = this.searchCompanyControl.valueChanges.pipe(
            startWith(''),
            map((value: string | CompanyDTO) => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filterConnectors(name) : this.connectorList.slice();
            })
          );
        },
        error: (error) => {
          this.loadingStatus.next(true);
          this.alertHandlerService.showError(error);
        }
      });
  }

  _filterConnectors(value: string): CompanyDTO[] {
    const filterValue = value.toLowerCase();
    return this.connectorList.filter(company =>
      company.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(company: CompanyDTO): string {
    return company && company.name ? company.name : '';
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent) {
    const company: CompanyDTO = event.option.value;
    this.connectorList2 = company.connectorEndpoint;
    this.connectorBpn = company.bpn;
    this.searchUrl = '';

    console.log("Company select: ", this.searchUrl);
  }

  onConnectorSelected(event: MatSelectChange) {
    const selectedValue: string = event.value;

    this.searchUrl = selectedValue;

    console.log("Connector select: ", this.searchUrl);
  }

  /**
   * Sets the default connector based on the environment configuration.
   * 
   * This method performs the following actions:
   * 1. Finds the default company from the `connectorList` using the `bpn` value from the environment configuration.
   * 2. If the default company is found:
   *    - Sets the autocomplete control to the default company.
   *    - Updates the `connectorBpn` with the default company's `bpn`.
   *    - Updates the `connectorList2` with the default company's connector endpoints.
   *    - Sets the `searchUrl` to the connector endpoint that matches the `edcProtocolUrl` from the environment configuration.
   * 3. If the default company is not found, logs a message to the console.
   */
  setDefaultConnector() {
    const defaultCompany = this.connectorList.find(company => company.bpn === environment.edcBpn);

    if (defaultCompany) {
      // Set autocomplete default company
      this.searchCompanyControl.setValue(defaultCompany);

      this.connectorBpn = defaultCompany.bpn;

      this.connectorList2 = defaultCompany.connectorEndpoint;

      // Set default connector
      if (this.connectorList2 && this.connectorList2.length > 0) {
        this.searchUrl = this.connectorList2.find(connector => connector === environment.edcProtocolUrl);
      }
    } else {
      console.log('Default company not found');
    }
  }

  createCatalogRequestBody() {
    const catalogDto: CatalogDTO = {
      counterPartyAddress: this.searchUrl,
      counterPartyId: this.connectorBpn,
      assetLimit: this.assetLimit,
      assetType: (this.searchBy === this.searchByTypeValue && this.assetType != '') ? this.assetType : null,
      assetId: (this.searchBy === this.searchByIdValue) ? this.assetId : null
    };

    return catalogDto;
  }

  processCatalogResponse(res: any) {
    this.dataSource.data = res.map((x: any) => ({
      participantId: x.participantId,
      connectorUrl: x.connectorUrl,
      assetId: x.assetId,
      type: x.type,
      usagePolicy: x.usagePolicy
    }));
  }

  searchCatalog() {
    sessionStorage.setItem('searchUrl', this.searchUrl);
    const body = this.createCatalogRequestBody();

    this.dataLoaded = false;

    this.http.post<any>("/api/v1/edc/catalog", body, this.httpOptions)
      .pipe(map(res => this.processCatalogResponse(res)))
      .subscribe({
        next: () => {
          this.dataLoaded = true;
        },
        error: (error) => {
          this.dataLoaded = true;
          this.alertHandlerService.showError(error);          
        },
      });
  }

  private readonly loadingStatus = new BehaviorSubject<boolean>(false);
  private readonly errorStatus = new BehaviorSubject<boolean>(false);

  negotiateContract(asset: Asset) {
    this.loadingStatus.next(false);
    this.errorStatus.next(false);

    const dialogRef = this.dialog.open(LoadingPopupComponent, {
      data: {
        dialogTitle: 'Contract Negotiation',
        dialogContent: "Negotiating contract...",
        dialogContentSuccess: "Contract negotiated successfully!",
        dialogContentError: "Failed to negotiate contract.",
        dataLoaded: this.loadingStatus.asObservable(),
        hasError: this.errorStatus.asObservable()
      },
      panelClass: 'custom-dialog-container',
      position: { top: '100px' },
      maxWidth: '90vw',
      width: '60vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) this.navigateToTransferData();
    });

    this.http.post<any>("/api/v1/edc/negotiate-contract", asset, this.httpOptions)
      .subscribe({
        next: (response) => {
          this.loadingStatus.next(true);
          this.errorStatus.next(false);

          this.createTransferData(asset, response);
        },
        error: (error) => {
          this.loadingStatus.next(true);
          this.errorStatus.next(true);
          this.alertHandlerService.showError(error);
        }
      });
  }

  createTransferData(asset: Asset, edrNegotiationResponse: any) {
    this.transferData = {
      method: 'GET',
      assetId: asset.assetId,
      endpoint: edrNegotiationResponse.endpoint,
      token: edrNegotiationResponse.authorization,
      body: null
    }
  }

  navigateToTransferData() {
    const transferKey = 'transfer-' + Date.now();
    const transferData = {
      assetId: this.transferData.assetId,
      endpoint: this.transferData.endpoint,
      token: this.transferData.token
    };

    sessionStorage.setItem(transferKey, JSON.stringify(transferData));

    const url = this.router.createUrlTree(['/edc-transfer-data'], {
      queryParams: { transferKey }
    }).toString();

    window.open(url, '_blank');
  }

  openAssetPopup(assetId: string) {
    const asset = this.dataSource.data.find(x => x.assetId === assetId);

    if (asset) {
      let json: string = JSON.stringify(asset, null, 2);

      this.dialog.open(ConfirmPopupComponent, {
        data: {
          dialogTitle: 'Asset',
          dialogContent: `<pre id="pre">${json}</pre>`,
          buttonText: 'Ok'
        },
        panelClass: 'custom-dialog-container',
        position: { top: '100px' },
        maxWidth: '90vw',
        width: '60vw'
      });
    } else {
      console.error('Asset not found');
    }
  }

  extractSubmodelName(uri: string | null | undefined): string {
    if (!uri) return 'Undefined';
    const parts = uri.split('#');
    return parts.length > 1 ? parts[1] : 'Undefined';
  }

  get isSearchCatalogButtonDisabled(): boolean {
    return !this.searchCompanyControl.value || !this.connectorBpn || !this.searchUrl;
  }
}
