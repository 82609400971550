import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertHandlerService } from '../shared/services/alert-handler.service';

@Component({
  selector: 'app-lookup-data-model',
  templateUrl: './lookup-data-model.component.html',
  styleUrls: ['./lookup-data-model.component.css']
})
export class LookupDataModelComponent {
  form: FormGroup;

  breadcrumb = [{ title: 'Lookup Data Model', href: '' }];

  aspectModels: AspectModel[] = [
    { value: 'batch_3.0.0', viewValue: 'Batch 3.0.0' },
    { value: 'partTypeInformation_1.0.0', viewValue: 'PartTypeInformation 1.0.0' },
    { value: 'singleLevelUsageAsBuilt_3.0.0', viewValue: 'SingleLevelUsageAsBuilt 3.0.0' },
    { value: 'singleLevelUsageAsPlanned_2.0.0', viewValue: 'SingleLevelUsageAsPlanned 2.0.0' },
    { value: 'transmissionPass_1.0.0', viewValue: 'TransmissionPass 1.0.0' },
  ]

  aspectModelResult: string = '{ Empty }'

  constructor(
    private readonly alertHandlerService: AlertHandlerService, 
    private readonly http: HttpClient, 
    private readonly formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      aspectModel: [{ value: '' }, [Validators.required]],
      digitalTwinId: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  getAspectModel() {
    if (this.form.valid) {

      interface AspectModelRequestDTO {
        aspectModel: string;
        digitalTwinId: string;
      }

      const formData: AspectModelRequestDTO = this.form.value;

      this.http.get<any>(`/api/v1/lookup/${formData.aspectModel.toLowerCase()}/${formData.digitalTwinId}/submodel`, this.httpOptions).subscribe(
        (response) => {
          this.aspectModelResult = JSON.stringify(response, null, 2);
        },
        (error) => {
          this.alertHandlerService.showError(error);
        }
      )
    }
  }

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }
}

interface AspectModel {
  value: string;
  viewValue: string;
}
