import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Input } from '@angular/core';
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
  selector: 'app-loading-status',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatIconModule,
    CommonModule
  ],
  templateUrl: './loading-status.component.html',
  styleUrl: './loading-status.component.css'
})
export class LoadingStatusComponent {
  @Input() dataLoaded: boolean = false;
  @Input() hasError: boolean = false;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  iconSize = 100;
}
