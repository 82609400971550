<app-card-layout [title]="'PartTypeInformation Aspect Models'" [buttonText]="'Create PartTypeInformation'"
                 [buttonRoute]="'/part-type-information/new'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="digitalTwinId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by digitalTwinId">
          Digital Twin Id Associated
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.digitalTwinId }}</td>
      </ng-container>

      <ng-container matColumnDef="materialClassification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by materialClassification">
          Material Classification
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.materialClassification }}</td>
      </ng-container>

      <ng-container matColumnDef="materialNameManufacturer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by materialNameManufacturer">
          Material Name Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.materialNameManufacturer }}</td>
      </ng-container>

      <ng-container matColumnDef="isRegistered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by registered"> Is Registered
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="{{'isRegistered-'+element.isRegistered}}">{{ element.isRegistered }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
          <button mat-icon-button [routerLink]="['/part-type-information/new/', element.id]"
                  matTooltip="View or Modify">
            <mat-icon color="primary">visibility</mat-icon>
          </button>

          <button mat-icon-button [matMenuTriggerFor]="mainmenu" matTooltip="Menu">
            <mat-icon color="primary">more_vert</mat-icon>
          </button>
          <mat-menu #mainmenu="matMenu">
            <button mat-menu-item (click)="registerPartTypeInformation(element.id)"
                    *ngIf="!element.isRegistered; else unregisterButton">
              <mat-icon class="icon-register">cloud_upload</mat-icon>
              Register
            </button>

            <ng-template #unregisterButton>
              <button mat-menu-item (click)="unregisterPartTypeInformation(element.id)">
                <mat-icon class="icon-unregister">cloud_download</mat-icon>
                Unregister
              </button>
            </ng-template>

            <button mat-menu-item (click)="getComponentHistory(element.id)">
              <mat-icon color="primary">history</mat-icon>
              History
            </button>

            <button mat-menu-item (click)="openConfirmationPopup(element.id)">
              <mat-icon color="warn">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-table>
</app-card-layout>
