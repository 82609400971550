<div class="spinner-container">
    <mat-progress-spinner *ngIf="!dataLoaded" [color]="color" [mode]="mode" [value]="value" style="display: inline-block;">
    </mat-progress-spinner>

    <mat-icon *ngIf="dataLoaded && !hasError" [color]="color" class="success-icon"
        [style.width.px]="iconSize" [style.height.px]="iconSize" [style.fontSize.px]="iconSize">
        check_circle
    </mat-icon>

    <mat-icon *ngIf="hasError" [color]="color" class="success-icon"
        [style.width.px]="iconSize" [style.height.px]="iconSize" [style.fontSize.px]="iconSize">
        cancel
    </mat-icon>
</div>