import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertHandlerService {

  constructor(private readonly snackBar: MatSnackBar) { }

  showError(error: any): void {
    const errorMessage = error.error.message || error.error.error || error.error || 'An unknown error occurred';
    this.activateAlert('red-snackbar', errorMessage);
  }

  showMessage(message: string, isError: boolean = false): void {
    const alertType = isError ? 'red-snackbar' : 'green-snackbar';
    this.activateAlert(alertType, message);
  }

  private activateAlert(type: string, message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }
}