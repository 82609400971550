<h1 mat-dialog-title>{{ data.dialogTitle }}</h1>
<div mat-dialog-content style="display: flex; margin: auto;">
  <div style="text-align: center;">
    <div *ngIf="!currentLoadingStatus" [innerHTML]="data.dialogContent"></div>
    <div *ngIf="currentLoadingStatus && !hasError" [innerHTML]="data.dialogContentSuccess"></div>
    <div *ngIf="currentLoadingStatus && hasError" [innerHTML]="data.dialogContentError" 
         style="color: red;"></div>
    <app-loading-status [dataLoaded]="currentLoadingStatus" [hasError]="hasError"></app-loading-status>
  </div>
</div>
<div mat-dialog-actions class="mat-dialog-actions">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button mat-raised-button color="primary" [disabled]="!currentLoadingStatus || hasError"
    (click)="dialogRef.close(true)">Transfer Data</button>
</div>