import { Component } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDeletePopupComponent } from '../../shared/popup/confirm-delete-popup/confirm-delete-popup.component';
import { Batch } from 'src/app/shared/models/aspects/batch.dto';
import { RegistryAspectModel } from 'src/app/shared/models/aspects/registry-aspect-model.dto';
import { MessageService } from 'src/app/shared/services/message.service';
import { HelpersService } from "../../shared/services/helpers.service";
import { AlertHandlerService } from 'src/app/shared/services/alert-handler.service';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.css']
})
export class BatchComponent {
  dataSource = new MatTableDataSource<Batch>();
  dataLoaded = false;
  displayedColumns: string[] = ['digitalTwinId', 'materialClassification', 'materialNameManufacturer', 'isRegistered', 'options'];

  showFirstLastButtons = true;

  constructor(
    private readonly alertHandlerService: AlertHandlerService,
    private readonly messageService: MessageService,
    private readonly http: HttpClient,
    public dialog: MatDialog) {
    this.getInformation();
  }

  getInformation() {
    this.http.get<any>("/api/v1/batch/test").pipe(map(res => res.map((x: Batch) => ({
      id: x.id,
      cxIdentificationList: x.cxIdentificationList,
      digitalTwinId: x.digitalTwinId,
      isRegistered: x.isRegistered,
      materialClassification: x.materialClassification,
      materialNameManufacturer: x.materialNameManufacturer
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      },
      error => {
        this.dataLoaded = true;
      }
    );

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.alertHandlerService.showMessage(message.text);
      }
    });
  }

  getComponentHistory(id: string) {
    HelpersService.getComponentHistory(id, false, this.http, this.dialog);
  }

  registerBatch(aspectModelId: string) {
    this.dataLoaded = false;

    const registryAspectModel: RegistryAspectModel = {
      aspectModelId: aspectModelId,
      aspectModelType: 'batch'
    }

    this.http.post<Batch>('/api/v1/registry/aspectmodel', registryAspectModel).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Batch registered successfully!");

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    )
  }

  unregisterBatch(aspectModelId: string) {
    this.dataLoaded = false;

    const registryAspectModel: RegistryAspectModel = {
      aspectModelId: aspectModelId,
      aspectModelType: 'batch'
    }

    this.http.delete('/api/v1/registry/aspectmodel', { body: registryAspectModel }).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Batch unregistered successfully!");

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      }
    )
  }

  deleteBatch(aspectModelId: string) {
    this.http.delete(`/api/v1/batch/${aspectModelId}`).subscribe(
      (response) => {
        this.alertHandlerService.showMessage("Batch deleted successfully!");

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;
        this.alertHandlerService.showError(error);
      })
  }

  openConfirmationPopup(aspectModelId: string) {
    const dialogRef = this.dialog.open(ConfirmDeletePopupComponent, {
      data: { elementToDelete: 'Batch', id: aspectModelId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.deleteBatch(aspectModelId);
    });
  }

}
