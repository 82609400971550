import { Component, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Group } from 'src/app/core/models/group';

interface MenuItem {
  name: string;
  dropdownOption: boolean,
  children: { name: string; link: string; }[];
  requiredGroups?: Group[];
}

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.css'
})
export class TopMenuComponent {
  @Input() isUserLogged: boolean;

  menuItems: MenuItem[] = [
    {
      name: 'DT Catalog (Automated)',
      dropdownOption: true,
      children: [
        { name: 'Digital Twins', link: '/digital-twin' },
        { name: 'Add shared Items', link: '/shared-items-new' },
        { name: 'Shared Items', link: '/shared-item' }
      ]
    },
    {
      name: 'DT Creation (Manual)',
      dropdownOption: true,
      children: [
        { name: 'Digital Twins Test', link: '/digital-twin-test' },
        { name: 'Batch Aspect Models', link: '/batch' },
        { name: 'Part Type Information Aspect Models', link: '/part-type-information' }
      ],
      requiredGroups: [Group.ADMIN, Group.DEVELOPER, Group.MP_OD1],
    },
    {
      name: 'API',
      dropdownOption: true,
      children: [
        { name: 'Lookup Data Model', link: '/lookup-data-model' }
      ],
      requiredGroups: [Group.ADMIN, Group.DEVELOPER, Group.MP_OD1],
    },
    {
      name: 'Notifications',
      dropdownOption: true,
      children: [
        { name: 'Quality', link: '/quality-notifications' },
        { name: 'Unique ID Push', link: '/uniqueidpush-notifications' }
      ],
      requiredGroups: [Group.ADMIN, Group.DEVELOPER],
    },
    {
      name: 'DTR Catalog',
      dropdownOption: false,
      children: [
        { name: '', link: '/dtr-catalog' },
      ],
      requiredGroups: [Group.ADMIN, Group.DEVELOPER],
    },
    {
      name: 'EDC Catalog',
      dropdownOption: false,
      children: [
        { name: '', link: '/edc-catalog' }
      ],
      requiredGroups: [Group.ADMIN, Group.DEVELOPER],
    }
  ];

  currentUrl: string = '';

  constructor(private router: Router,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.urlAfterRedirects;
    });
  }

  isActive(item: MenuItem): boolean {
    return item.children.some(child => this.currentUrl === child.link);
  }

  canShowMenuItem(item: MenuItem): boolean {
    if (!item.requiredGroups) return true;
    return this.authenticationService.isUserInAtLeastInOneOfGroups(item.requiredGroups);
  }
}
