import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatFormFieldModule } from '@angular/material/form-field';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressSpinnerComponent]
})
export class TableComponent {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() dataLoaded: boolean;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showFirstLastButtons = true;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
